<template>
  <div class="home">
    <div class="top">
      <img src="../assets/image/log.png" alt="" />
      <div class="search">
        <el-select
          v-model="value"
          filterable
          remote
          :remote-method="remoteMethod"
          placeholder="请输入关键词"
          :loading="loading" >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value" >
          </el-option>
        </el-select>

        <el-button
          type="primary"
          icon="el-icon-search"
          @click="search()"
          style="background-color: #01847f" />
      </div>

              <div class="tuichu"       @click="tuichu()">
                退出登录
              </div>
              <div v-if="baseGetSeparate == 2?false:true" class="resetSelfPwd" @click="resetSelfPwd()">
                修改密码
              </div>

              <div class="yonghu">
                <p>{{baseGetSeparate == 1?"平台":"商家"}}</p>
                <p>{{mainRoleName}}</p>
              </div>
              

    </div>

    <el-row class="tac">
      <el-col :span="12">
        <el-radio-group v-model="isCollapse" class="radio-grou">
          <el-radio-button
            :label="false"
            class="radio-grou-li"
            :class="isCollapse ? 'btnactives' : 'btnactive'"
            ><i class="el-icon-s-unfold"></i
          ></el-radio-button>
          <el-radio-button
            :label="true"
            class="radio-grou-li"
            :class="isCollapse ? 'btnactive' : 'btnactives'"
            ><i class="el-icon-s-fold"></i
          ></el-radio-button>
        </el-radio-group>
        <el-menu
          unique-opened
          default-active="2"
          class="el-menu-vertical-demo"
          :class="isCollapse == false ? 'one' : 'two'"
          @open="handleOpen"
          @close="handleClose"
          :collapse="isCollapse"
          router>
          <el-submenu
            v-for="(item, i) in navIcon"
            :key="i"
            :index="item.id.toString()">
            <template slot="title">
              <i :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="(items, i) in item.dda"
                :key="i"
                :index="items.alink">
                {{ items.name }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>

    <el-main class="detailed-content">
      <router-view />
    </el-main>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 判断商家端和会员端
      baseGetSeparate:this.$common.baseGetSeparate(),

      isCollapse: false,

      // 角色
      role:"",
      // 侧边栏导航和搜索
      navIcon: [],
      states: [],

      options: [],
      value: '',
      list: [],
      loading: false,
    };
  },
  created() {
    this.mainRoleName =this.$common.baseGetRoute().mainRoleName
    this.navIcon = this.$common.baseGetRoute().navIcon;
    this.states = this.$common.baseGetRoute().states;

    console.log(this.$common.baseGetRoute())
  },
  mounted() {
    // 搜索
    this.list = this.states.map((item) => {
      return {
        value: `${item.alink}`,
        label: `${item.name}`,
      };
    });
  },

  methods: {
    // 修改密码
    resetSelfPwd(){
      this.$router.push({ path: `/home/resetSelfPwd` });
    },
    // 退出登录
    tuichu(){
      this.$router.push({ path: `/` });
    },
    // 远程搜索
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      } 
    },
    search() {
      if (this.value == '') {
        this.$alert('请选择一条数据', '消息提示', {confirmButtonText: '确定'});
      }else{
      this.$router.push({ path: this.value });
      setTimeout(() => {
        this.$router.go(0);
      }, 200);
      }

    },

    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
  },
  computed: {},
};
</script>


<style  scoped>
.tac {
  position: relative;
  float: left;
}
.el-menu-vertical-demo {
  height: auto;
  transition: all 0.5s;
}
.el-menu-vertical-demo.one {
  width: 200px;
}
.el-menu-vertical-demo.two {
  width: 70px;
}
.top {
  width: 100%;
  height: 54px;
  border: 1px solid rgba(153, 153, 153, 0.445);
}
.top img {
  float: left;
  height: auto;
  width: 200px;
}
.search {
  padding-top: 6px;
  float: left;
  width: 300px;
  height: 50px;
}

.resetSelfPwd{
  float: right;
  line-height: 44px;
  padding-right: 40px;
  cursor: pointer;
}
.tuichu{
    float: right;
  line-height: 44px;
  padding-right: 50px;
  cursor: pointer;
}
.yonghu{
  float: right;
  padding-right: 50px;
}


.radio-grou {
  position: absolute;
  right: -50px;
  padding: 0 !important;
}

.btnactive {
  display: none;
}
.btnactives {
  display: block;
}
</style>